import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    // Asegúrate de que window esté definido
    if (typeof window !== "undefined") {
      const storedTheme = window.localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
      
      // Aquí también asegúrate de que document esté disponible
      document.body.classList.toggle('dark-mode', storedTheme === 'dark');
      const backgroundProperty = storedTheme === 'dark' ? '#000625' : '#f4f8fd';
      document.documentElement.style.setProperty('--color-background', backgroundProperty);
      
      setTheme(storedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    if (typeof window !== "undefined") {
      setTheme(newTheme);
      window.localStorage.setItem('theme', newTheme);
      
      // Y aquí también
      document.body.classList.toggle('dark-mode', newTheme === 'dark');
      const backgroundProperty = newTheme === 'dark' ? '#000625' : '#f4f8fd';
      document.documentElement.style.setProperty('--color-background', backgroundProperty);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
