import React from 'react';
import { ThemeProvider } from './src/context/ThemeContext';
import "./src/components/layout.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const onInitialClientRender = () => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const savedLanguage = localStorage.getItem('preferredLanguage');
  const firstVisit = localStorage.getItem('firstVisit'); // Chequear si es la primera visita

  // Determinar el idioma preferido: primero chequea localStorage, luego el idioma del navegador
  const preferredLanguage = savedLanguage || (userLanguage.toLowerCase().includes('es') ? 'es' : 'en');

  // Si es la primera visita y no hay preferencia guardada, redirigir según el idioma del navegador
  if (!firstVisit) {
    localStorage.setItem('firstVisit', 'true'); // Guardar que el usuario ya ha visitado la página
    if (preferredLanguage === 'es' && window.location.pathname !== '/es/') {
      window.location.replace('/es/');
    } else if (preferredLanguage === 'en' && window.location.pathname !== '/') {
      window.location.replace('/');
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);
